<template>
  <div>
    <div class="min-h-50 d-md-flex w-100 align-items-center justify-content-center" v-if="!hasSnapshot && !loading">
      <div class="text-center my-5">
        <b-icon icon="person-badge" class="h1" />
        <p class="my-3">
          Ainda não há informações sobre a Gestão de Pessoas da empresa.
        </p>
        <b-button @click="handleSyncronize()" v-if="is_provider && enabled">
          <b-icon icon="arrow-down-up" class="mr-md-2" />
          <span>Sincronizar agora</span>
        </b-button>
      </div>
    </div>
    <div v-else>
      <div class="d-md-flex align-items-center justify-content-start">
        <small class="mr-2">
          Ultima atualização {{ data.updated_at | dateFull }}
        </small>
        <unicon v-if="is_provider && enabled" name="redo" @click="handleSyncronize()" class="pointer small" height="16"
          width="16" />
      </div>
      <small class="d-block mb-4">
        Os dados de Benefícios estão incorretos? Altere-os
        <router-link target="_blank" to="/my-enterprise/human-resources/jobs-position">
          na gestão de pessoas de sua empresa
        </router-link>
      </small>
      <h6 class="mt-4">Benefícios</h6>
      <div class="d-md-flex mt-3 w-100 justify-content-between">
        <b-card class="w-100" v-for="(voucher, index) in data.vouchers" :key="index"
          :class="index === data.vouchers.length - 1 ? 'mr-0' : 'mr-4'">
          <span style="height: 40px">A empresa fornece {{ voucher.name }}?</span>
          <section class="
              d-md-flex
              w-100
              justify-content-between
              align-items-center
              mt-1
            ">
            <strong class="m-0" :class="voucher.has_benefit ? 'text-success' : 'text-danger'">
              {{ voucher.has_benefit ? "Fornece" : "Não fornece" }}
            </strong>
          </section>
          <div v-if="voucher.has_benefit">
            <hr />
            <span>
              O benefício é pago em
              <strong>
                {{ voucher.payment === 1 ? "cartão" : "dinheiro" }}
              </strong>
            </span>
            <span v-if="voucher.payment === 1">
              pela empresa <strong>{{ voucher.enterprise.name }}.</strong>
            </span>
            <span v-if="voucher.payment === 1" class="d-block mt-2">
              Representante:
              <strong>{{ voucher.enterprise.contact }}</strong>
            </span>
          </div>
        </b-card>
      </div>
      <hr />
      <h6>Pagamentos</h6>
      <div class="d-md-flex mt-3 w-100">
        <b-card class="w-100 mr-4">
          <span class="d-block" style="height: 63px">Dia do pagamento</span>
          <strong class="d-block mt-2">
            {{ data.payment_day }}° dia útil</strong>
        </b-card>
        <b-card class="w-100 mr-4">
          <span style="height: 63px">
            As Horas Extraordinárias realizadas são pagas corretamente na Folha?
          </span>
          <strong class="d-block mt-2" :class="data.overtime_hours_paid_with_legal_additions
            ? 'text-success'
            : 'text-danger'
            ">
            {{ data.overtime_hours_paid_with_legal_additions ? "Sim" : "Não" }}
          </strong>
        </b-card>
        <b-card class="w-100">
          <span style="height: 63px">
            As Horas Extraordinárias realizadas são pagas com adicioanis legais
            e previsto em Convênção Coletiva correntamente?
          </span>
          <strong class="d-block mt-2" :class="data.overtime_hours_paid_correctly
            ? 'text-success'
            : 'text-danger'
            ">
            {{ data.overtime_hours_paid_correctly ? "Sim" : "Não" }}
          </strong>
        </b-card>
      </div>
      <hr />
      <h6>SESMET</h6>
      <div class="d-md-flex mt-3 w-100">
        <b-card class="w-100 mr-4">
          <span class="d-block">Setor de Segurança e Medicina do trabalho é próprio ou
            Terceirizado?</span>
          <strong class="d-block" :class="data.sesmet && data.sesmet.sector.own
            ? 'text-success'
            : 'text-danger'
            ">
            {{ data.sesmet && data.sesmet.sector.own ? "Próprio" : "Terceirizado" }}
          </strong>
          <div v-if="data.sesmet && !data.sesmet.sector.own">
            <hr>
            <span class="d-block mt-2">
              CNPJ:
              <strong>{{ maskedCNPJ }}</strong>
            </span>
            <span class="d-block mt-2">
              email:
              <strong>{{ data.sesmet.sector.email }}</strong>
            </span>
          </div>
        </b-card>
        <b-card class="w-100">
          <span style="height: 63px">
            A empresa cumpre com SESMET de acordo com lei?
          </span>
          <strong class="d-block mt-2" :class="data.sesmet && data.sesmet.compliance.sesmet_law_compliance
            ? 'text-success'
            : 'text-danger'
            ">
            {{ data.sesmet && data.sesmet.compliance.sesmet_law_compliance ? "Sim" : "Não" }}
          </strong>
          <div v-if="data.sesmet && data.sesmet.compliance.sesmet_law_compliance">
            <hr>
            <span class="d-block mt-2">
              Representante:
              <strong>{{ data.sesmet.compliance.sesmet_responsible_name }}</strong>
            </span>
            <span class="d-block">
              Email:
              <strong>{{ data.sesmet.compliance.sesmet_responsible_email }}</strong>
            </span>
            <span class="d-block">
              Comprovante:
              <strong class="pointer d-inline" @click="openFile">{{ fileSesmetName() }} <b-icon
                  icon="paperclip" /></strong>

            </span>
          </div>
        </b-card>
      </div>
      <hr />
      <div class="d-md-flex">
        <div class="w-100 mr-4 px-0">
          <h6>Efetivo ativo</h6>
          <b-card class="w-100 mr-4">
            <div class="d-flex justify-content-between align-items-center w-100">
              <strong class="d-flex flex-1"> Total</strong>
              <span class="d-flex flex-1 justify-content-end">
                {{ data.total_employers }} Colaboradores
              </span>
            </div>
            <hr class="mt-1" />
            <div class="d-flex justify-content-between align-items-center w-100">
              <strong class="d-flex flex-1"> Estoque processo</strong>
              <span class="d-flex flex-1 justify-content-end">
                {{ (data.process_stock * 100).toFixed(2) }}%
              </span>
            </div>
          </b-card>
          <b-card class="mt-4">
            <div id="chart">
              <ApexCharts type="area" height="350" :options="chartOptions" :series="series" ref="chartApex" />
            </div>
          </b-card>
        </div>
        <div class="w-100 px-0">
          <h6>Rotatividade</h6>
          <b-card class="w-100 mr-4">
            <section class="w-100" v-for="(trn, index) in data.turnover" :key="index">
              <div class="d-flex justify-content-between align-items-center w-100">
                <strong class="d-flex flex-1"> {{ trn.month }}</strong>
                <span class="d-flex flex-1 justify-content-end">
                  {{ trn.percent.toFixed(2).replace(/[.,]00$/, "") }} %
                </span>
              </div>
              <hr class="mt-1" />
            </section>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ApexCharts from "vue-apexcharts";
import { url } from "@/services/api";

export default {
  components: {
    ApexCharts,
  },
  data() {
    return {
      hasSnapshot: false,
      enabled: false,
      isEmpty: false,
      loading: false,
      data: {},
      provider_id: "",
      service_id: "",
      series: [
        {
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "area",
          height: 350,
          toolbar: {
            show: false,
            autoSelected: "pan",
          },
          animations: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        title: {
          text: "Rotatividade 12 meses",
          align: "left",
          style: {
            fontSize: "14px",
          },
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          tickAmount: 10,
          floating: false,

          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        fill: {
          opacity: 0.5,
        },
        tooltip: {
          fixed: {
            enabled: true,
            position: "topRight",
          },
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30,
            },
          },
          padding: {
            left: 20,
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters(["is_preview", "user", "preview_personify", "is_provider"]),
    maskedCNPJ() {
      const cnpj = this.data.sesmet.sector.cnpj;
      if (!cnpj) return '';

      // Format CNPJ to ##.###.###/####-##
      return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    }
  },
  methods: {
    ...mapActions(["get_snapshot", "set_snapshot", "show_loading"]),
    fileSesmetName() {
      if (this.data.sesmet && Object.keys(this.data.sesmet.compliance.file).length > 0) {
        return this.data.sesmet.compliance.file[0].name
      } else {
        return null
      }
    },
    openFile() {
      const urlApi = url.replace('api', '');
      const fileUrl = `${urlApi}${this.data.sesmet.compliance.file[0].file}`;

      window.open(fileUrl, '_blank');
    },
    handleClickChange() {
      this.invertValue = !this.invertValue;
    },
    getSnapshot() {
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "human-resources",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then((response) => {
        this.enabled = response.enabled;
        this.$refs.chartApex.refresh()
        if (!response.updated_at) {
          this.hasSnapshot = false;
          this.loading = false;
          return;
        } else {
          this.hasSnapshot = true;
          this.data = response.data;
          this.data.turnover.map(({ month, percent }) => {
            this.series[0].data.push({
              x: month,
              y: percent.toFixed(2),
            });
          });
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },
    handleSyncronize() {
      this.$bvModal.show("modal-generating-snapshot");
      this.set_snapshot({
        type: "human-resources",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id || this.$route.query.provider_id;
    this.getSnapshot();
    this.$nextTick(() => {
      if (this.$refs.chartApex) {
        window.dispatchEvent(new Event('resize'));
      }
    });
  },
};
</script>
